<template>
  <div class="cust-header">
    <div
      class="cust-a"
      :style="diminish ? diminishA() : ''"
    />
    <div class="cust-b" />
    <div
      class="cust-c"
      :style="diminish ? diminishC() : ''"
    >
      <div class="cust-ca">
        <div class="cust-imgbox">
          <img
            :src="imgUrl"
            alt=""
          >
        </div>
        <slot>
          <div class="cust-content">
            <div class="cust-d">
              <div class="cust-da">
                {{ data.title }}
              </div>
              <div
                class="cust-db"
                :style="matchColor(data.status)"
              >
                {{ data.status }}
              </div>
            </div>
            <div
              v-for="(item , index) of data.mainList"
              :key="index"
              class="cust-e"
            >
              {{ item }}
            </div>
            <div
              v-if="data.tagList && data.tagList.length"
              class="cust-f"
            >
              <div
                v-for="(item , index) of data.tagList"
                :key="index"
                class="cust-fa"
              >
                {{ verdicts(item) }}
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { custDetail } from '_api/cust';

import { verdict } from '@/lib/until.js';
import { matchingColor } from '../../lib/until';

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    imgUrl: {
      type: String,
      default: require('../../assets/img/company.png')
    },
    diminish: {
      type: Boolean
    }
  },
  data() {
    return {
      custInfo: {
        licenseAfileUrl: require('@/assets/img/logo.png'),
        custCn: '杭州今元标矩科技有限公司',
        cooperationStatus: '已合作',
        source: '电话开发'
        // scale: '',
        // custInd: ''
      },
      list: [
        '500-100人',
        '软件和服务业信息技术服务业服务业服务业服务业'
      ]
    };
  },
  methods: {
    verdicts(str) {
      return verdict(str);
    },
    diminishA() {
      return {
        'height': '96' + 'px'
      };
    },
    diminishC() {
      return {
        'height': '114' + 'px',
        'margin-top': '-140' + 'px'
      };
    },
    getDetail() {
      custDetail(this.custId).then(res => {
        if (res.flag) {
          for (const i in this.custInfo) {
            this.custInfo[i] = res.data.custInfoDetail;
          }
          this.list.push(this.custInfo.scale, this.custInfo.custInd);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    matchColor(val) {
      return matchingColor(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-header{
  width:100%;
  height: 168px;
  .cust-a{
    width:100%;
    height:104px;
    background-color:$color-3FF;
  }
  .cust-b{
    width:100%;
    height:60px;
    background-color:$color-F7;
  }
  .cust-c{
    height: 138px;
    margin: -148px 15px 0 13px;
    background-color: $color-FFF;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
    .cust-ca{
      display: flex;
      padding: 21px 14px 21px 14px;
      .cust-imgbox{
        width:45px;
        height: 45px;
        margin-right: 9px;
        background-color:$color-01;
        border-radius: 50%;
        img{
          width:45px;
          border-radius: 50%;
        }
      }
      .cust-content{
        flex:1;
        text-align: left;
        .cust-d{
          display: flex;
          justify-content: space-between;
          .cust-da{
            overflow:hidden;
            color:$color-333;
            font-weight: 600;
            font-size: $font-15;
            line-height: 1.5;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .cust-db{
            flex: 0 0 60px;
            color:$color-3FF;
            font-weight:600;
            font-size: $font-15;
            text-align: right;
          }
        }
        .cust-e{
          margin-top:10px ;
          color:$color-999;
          font-size:$font-13;
          text-align: left;
        }
        .cust-f{
          display: flex;
          justify-content:flex-start;
          margin-top: 14px;
          .cust-fa{
            max-width:180px;
            margin-right: 9px;
            padding:5px 6px;
            color: $color-3FF;
            font-size:10px;
            background-color:$color-01;
            border-radius: 1px;
            @extend .one-ellipsis;
          }
          .cust-fa:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
}

</style>
