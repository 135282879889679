<template>
  <div class="look-box">
    <div
      v-for="(item , index) of needShowHide ? showHide ? list : list.slice(0, 5) : list"
      :key="index"
      class="look-line"
    >
      <div class="look-title">
        {{ item.title }}
      </div>
      <div
        v-if="item.value || item.value === 0"
        class="look-value"
        :class="item.callPhone ? 'activePhone' : ''"
        @click="actionOperation(item)"
      >
        {{ item.value }}
      </div>
      <div
        v-else-if="item.arrValue && item.arrValue.length"
        class="look-content"
      >
        <div
          v-for="(items , indexs) of item.arrValue"
          :key="indexs"
          class="look-value"
        >
          {{ items }}
        </div>
      </div>
      <div
        v-else-if="item.type === 'image' && item.image.length"
        class="look-image-box"
      >
        <div
          v-for="(items , indexs) of item.image"
          :key="indexs"
          class="look-image"
          @click="showImagePreview(item.image)"
        >
          <img
            :src="items"
            alt=""
          >
        </div>
      </div>
      <!-- <div
        v-else-if="item.type === 'textImg'"
        class="look-value"
        @click="showImagePreview(item.image)"
      >
        {{ item.text }}
      </div> -->
      <div
        v-else-if="item.type === 'textImg'"
        class="look-value"
      >
        <a
          :href="item.image[0]"
          :download="item.text"
        >{{ item.text }}</a>
      </div>
    </div>
    <div
      v-if="needShowHide && !showHide && list.length > 5"
      class="accordion-imgbox"
      @click="operation"
    >
      <span>更多</span>
      <img
        :src="showHide ? imgsd : imgs"
        alt=""
      >
    </div>
  </div>
</template>

<script>
// import { geWxAgentConfigMaterial } from '_lib/material-weChat';
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    needShowHide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showHide: false,
      imgs: require('../../assets/img/down@2x.png'),
      imgsd: require('../../assets/img/up@2x.png')
    };
  },
  // created() {
  //   geWxAgentConfigMaterial();
  // },
  methods: {
    showImagePreview(imgs) {
      this.$createImagePreview({
        imgs: imgs
      }).show();
    },
    showfile(data) {
      // wx.previewFile({
      //   url: data.image[0], // 需要预览文件的地址(必填，可以使用相对路径)
      //   name: data.name, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
      //   size: data.size, // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
      // });
      wx.previewImage({
        current: data.image[0], // 第一张显示的图片链接
        urls: data.image // 需要预加载的图片http链接列表，预加载后，可以滑动浏览这些图片
      });
    },
    operation() {
      this.showHide = true;
    },
    actionOperation(data) {
      if (data.callPhone && data.value !== '') {
        this.callPhone(data.value)
      }
    },
    callPhone(tel) {
      window.location.href = 'tel:' + tel;
    }
  }
};
</script>

<style  lang="scss" scoped>
.look-box{
  width: 100%;
  overflow: hidden;
  background-color: $color-FFF;
  .look-line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: $font-14;
    .look-title{
      color: #999BA3;
    }
    .look-value{
      width:180px;
      color: #23252E;
      line-height: 1.5;
      white-space:normal;
      text-align: right;
      word-break:break-all;
      a{
        color: #23252E;
      }
    }
    .activePhone{
      color: $color-theme
    }
    .look-content{
      display: flex;
      flex-direction: column;
    }
    .look-image-box{
      display: flex;
      justify-content: flex-start;
      .look-image{
        width:50px;
        height: 35px;
        margin-right:4px ;
        img{
          width:50px;
          height: 35px;
        }
      }
    }
  }
  .accordion-imgbox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    color: #999BA3;
    font-size: 12px;
    line-height: 16px;
    img{
      width: 17px;
    }
  }
}
</style>
